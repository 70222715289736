import React, { useState } from "react"
import Subheading from "../../components/Subheading"
import CommunityCard from "../../components/Cards/CommunityCard"
import CommunityCards from "../../components/Cards/CommunityCards"
import Pagination from "../../components/Pagination"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import "./../../styles/dark-mode.scss"
import { first } from "lodash"

export default ({
  data: {
    pageInfo: { title, description },
    featured: { nodes: featured },
    featuredOldest: { nodes: featuredOldest },
    communityPosts: { nodes: communityPosts, totalCount },
    communityPostsOldest: { nodes: communityPostsOldest },
  },
  location: { state },
}) => {
  const getItems = (mode, type = "community") => {
    let items = {}
    switch (mode) {
      case "oldest":
        items = "community" === type ? communityPostsOldest : featuredOldest
        break
      case "newest":
      default:
        items = "community" === type ? communityPosts : featured
        break
    }
    return items
  }
  const [mode, setMode] = useState(state ? state.sort : "newest")
  const maxLimit = 9
  const [hasMore, setMore] = useState(getItems(mode).length > maxLimit) // eslint-disable-line no-unused-vars
  const [currentList, addToList] = useState([
    ...getItems(mode).slice(0, maxLimit),
  ])

  return (
    <Layout pageInfo={{ pageName: "First Responders Community" }}>
      <SEO
        title="First Responders Community"
        keywords={[`Community`, `first responders`]}
      />
      <Subheading title={title}>{description}</Subheading>
      <Pagination
        sort={true}
        sortMode={mode}
        onClickSort={mode => {
          setMode(mode)
          addToList([...getItems(mode).slice(0, maxLimit)])
        }}
        urlPrefix={`/first-responders/community`}
        currentPage={1}
        totalCount={1}
      />
      <CommunityCard isFeatured={true} {...first(getItems(mode, "featured"))} />
      <CommunityCards items={currentList} />
      <Pagination
        sorting={false}
        urlPrefix={`/first-responders/community`}
        loadMore={() => {
          const currentLength = currentList.length
          const more = currentLength < communityPosts.length
          const nextEdges = more
            ? getItems(mode).slice(currentLength, currentLength + maxLimit)
            : []
          setMore(more)
          addToList([...currentList, ...nextEdges])
        }}
        currentPage={1}
        totalCount={1}
      />
    </Layout>
  )
}

export const FPCommunityQuery = graphql`
  query CommunityQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-community" }) {
      title
      description
    }
    communityPosts: allNodeCommunityPost(
      sort: { order: DESC, fields: [created] }
    ) {
      nodes {
        id
        title
        body: body {
          value
        }
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    communityPostsOldest: allNodeCommunityPost(
      sort: { order: ASC, fields: [created] }
    ) {
      nodes {
        id
        title
        body: body {
          value
        }
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    featured: allNodeCommunityPost(
      sort: { order: DESC, fields: [created] }
      limit: 1
    ) {
      nodes {
        id
        title
        body: body {
          value
        }
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredOldest: allNodeCommunityPost(
      sort: { order: ASC, fields: [created] }
      limit: 1
    ) {
      nodes {
        id
        title
        body: body {
          value
        }
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
